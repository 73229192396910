const useColorTheme = (colorTheme) => {
  const background = colorTheme === "dark" ? " bg-secondary" : " bg-white "
  const text = colorTheme === "dark" ? " text-white " : " text-secondary "

  return ({
    backgroundClass: background,
    textClass: text
  });
}

export default useColorTheme;