import React from 'react';

import {Col} from "react-bootstrap";
import useColorTheme from "../../../hooks/useColorTheme";

const SubblockWrapper =({noBottom, fullwidth, children, colorTheme, position, noPadding}) => {
  const color = useColorTheme(colorTheme || "light")

  let className = color.backgroundClass
  className += noPadding ? "" :" p-5 "
  className += (position==="left" && !noPadding) ? " global-left " : ""
  className += (position==="right" && !noPadding) ? " global-right " : ""
  className += (position==="center" && !noPadding) ? " global-left global-right " : ""
  className += noBottom ? " pb-0 " : ""

  const lgColumns = fullwidth ? 12 : 6

  return (
    <>
      <Col xs={12} lg={lgColumns} className={className}>
        {children}
      </Col>
    </>
  );
}

export default SubblockWrapper;
