import React from "react"
import { graphql } from "gatsby"
import Seo from "../components/seo";
import Layout from "../components/common/layout/layout";
import SubblockRichText from "../components/common/subblock/subblock-rich-text";
import * as style from "../styles/impressum.module.scss"
import * as headerStyle from "../styles/header.module.scss"

const Impressum = ({ data }) => {
  // const header = data.strapiImpressum.header
  const text = data.strapiImpressum.impressumText

  return (
    <>
      <Seo title="Impressum" />
      <Layout>
        {/* Header Gap needed because no pages header with Picture is used */}
        <div className={headerStyle.headerGap} />
        <SubblockRichText content={text} position="center" fullwidth className={style.impressum}/>
      </Layout>
    </>
  )
}

export const pageQuery = graphql`
query strapiImpressumQuery {
  strapiImpressum {
    header {
      ...fragmentHeader
    }
    impressumText {
      __typename
      data {
        id
        childMarkdownRemark {
          html
        }
      }
    }
  }
}
`

export default Impressum
